import { getWithAuthDecorator, hasWindow } from '@services/http.service';
import * as CsrHttpService from "@http/csr-http.service";
import * as SsrHttpService from "@http/ssr-http.service";

const {
    GET_MAPPING,
    ADD_FORM_ID_TO_RULE_ENGINE,
    CREATE_SURVEY_FORM_RESPONSE,
    GET_USER_SURVEY,
    UPDATE_USER_SURVEY_FORM_VIEW_COUNT
} = require('@services/urls/survey');

export const getMapping = (ctx = null) => {
    const url = GET_MAPPING();
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

// As this Endpoint does not have a session, but invokes a secure endpoint
// Have changed the implementation a little.
export const getMappingWithAuthDecorator = (auth) => {
    const url = GET_MAPPING();
    return getWithAuthDecorator(url, auth);
}

export const addFormIdToRuleEngine = (ids, ctx = null) => {
    const url = ADD_FORM_ID_TO_RULE_ENGINE();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, { ids });
    }
    return SsrHttpService.postWithAuth(url, { ids }, ctx);
};

export const submitSurveyFormResponse = (formData, ctx = null) => {
    const url = CREATE_SURVEY_FORM_RESPONSE();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, formData);
    }
    return SsrHttpService.postWithAuth(url, formData, ctx);
};

export const getUserSurvey = (id, formid) => {
    const url = GET_USER_SURVEY(id, formid);
    if (hasWindow()) {
        return CsrHttpService.getWithOutAuth(url);
    }
    return SsrHttpService.getWithOutAuth(url);
};

export const updateUserSurveyCount = (data, ctx = null) => {
    const url = UPDATE_USER_SURVEY_FORM_VIEW_COUNT();
    if (hasWindow()) {
        return CsrHttpService.postWithOutAuth(url, data);
    }
    return SsrHttpService.postWithOutAuth(url, data);
};