import { appendCountryCodeAndMaskMobile, maskEmail } from "@helpers/miscelleanous";
import { replaceKeysWithValues } from "@helpers/string";
import texts from '@components/Auth/en.json';
import { otpLength } from "@ui/helpers/constants";
import Text from "@atoms/Text";

const MobileAuthenticationMessage = ({ otpModes, countryCode, mobileNumber }) => {
    const otpSentEmail = otpModes?.find(element => element.name === 'email');
    return <div className='p5-regular flex text-gray-900'>
        <Text isInnerHtml={true}
            content={
                `<p>${replaceKeysWithValues(texts?.OtpSentToMobile, { "{length}": otpLength })}
                    <span class="p5-medium">${appendCountryCodeAndMaskMobile(mobileNumber, countryCode)}</span>
                    ${otpSentEmail ? texts?.AndEmail + '<span class="p5-medium">' + maskEmail(otpSentEmail.to) + '</span>' : ''}
                </p>`
            }
        />
    </div>
}

export default MobileAuthenticationMessage;