/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Text } from '@atoms/index';
import { resendContextOtp, verifyContextOtp } from '@services/session.service';
import { Button } from '@ui/atoms/design-system';
import { appendCountryCodeAndFormatMobile } from '@helpers/miscelleanous';
import NextHeadTag from '@ui/molecules/NextHeadTag';
import { HeadTagData } from '@helpers/headTagData';
import texts from '@components/Auth/en.json';
import useOtpVerifier from '@ui/hooks/useOtpVerifier';
import EnterOTPCard from '@components/Auth/EnterOTPCard';
import { otpExpiredErrorWord, otpLength } from '@ui/helpers/constants';
import { replaceKeysWithValues } from '@helpers/string';
import { otpContextEnum } from '@ui/helpers/enum';
import MobileAuthenticationMessage from '@components/Auth/MobileAuthenticationMessage';

const VerifyPhoneAndEmailModal = ({
    show,
    title,
    subtitle,
    mobileContext,
    onClose,
    mobileNumber,
    emailId,
    editMobileNumber,
    editEmailId,
    countryCode,
    onSuccess,
    otpModes,
    shouldAuthenticateUser = true
}) => {
    const [loading, setLoading] = useState(false);
    const isIndianNumber = countryCode === '91';
    const shouldAskMobileOtp = (isIndianNumber || mobileContext !== otpContextEnum.MOBILE_VERIFICATION) && shouldAuthenticateUser;
    const mobileOtpVerifier = shouldAskMobileOtp
        ? useOtpVerifier({
            resendText: texts?.ResendOtp,
            id: "mobileNumberConfirmationOtp",
            otpContext: mobileContext,
        })
        : null;
    const emailOtpVerifier = useOtpVerifier({
        resendText: texts?.ResendOtp,
        shouldFocusByDefault: !shouldAskMobileOtp,
        id: "emailIdConfirmationOtp",
        otpContext: otpContextEnum.EMAIL_VERIFICATION
    });

    const { handleSubmit } = useForm();
    const [isOtpVerificationSuccess, setIsOtpVerificationSuccess] = useState(false);
    const [otpIdList, setOtpIdList] = useState([]);

    const editMobile = editMobileNumber ? () => {
        mobileOtpVerifier?.setOtpValue('');
        editMobileNumber();
    } : null;

    const editEmail = editEmailId ? () => {
        emailOtpVerifier.setOtpValue('');
        editEmailId();
    } : null;

    const onSubmit = async () => {
        mobileOtpVerifier?.setDisabled(true);
        emailOtpVerifier?.setDisabled(true);
        setLoading(true);
        let verifyMobileResponse, verifyEmailResponse;
        if (!isOtpVerificationSuccess) {
            if (shouldAskMobileOtp && !mobileOtpVerifier?.isVerified) {
                verifyMobileResponse = await verifyContextOtp({ mobile: mobileNumber, context: mobileOtpVerifier?.otpContext, otp: mobileOtpVerifier?.otpValue, });
                mobileOtpVerifier?.setIsVerified(verifyMobileResponse.status);
                mobileOtpVerifier?.setErrorMessage(verifyMobileResponse.status ? '' : verifyMobileResponse.message[0]);
                mobileOtpVerifier?.setError(!verifyMobileResponse.status);
                if (verifyMobileResponse.status) {
                    setOtpIdList(curList => [...curList, verifyMobileResponse.entity.id]);
                } else if (verifyMobileResponse?.message[0]?.includes(otpExpiredErrorWord)) {
                    mobileOtpVerifier?.setDisabled(true);
                    mobileOtpVerifier?.cancelTimer();
                } else {
                    mobileOtpVerifier?.setDisabled(false);
                }
            }
            if (!emailOtpVerifier.isVerified) {
                verifyEmailResponse = await verifyContextOtp({ mobile: mobileNumber, email: emailId, context: emailOtpVerifier.otpContext, otp: emailOtpVerifier.otpValue, });
                emailOtpVerifier.setIsVerified(verifyEmailResponse.status);
                emailOtpVerifier?.setErrorMessage(verifyEmailResponse.status ? '' : verifyEmailResponse.message[0]);
                emailOtpVerifier?.setError(!verifyEmailResponse.status);
                if (verifyEmailResponse.status) {
                    setOtpIdList(curList => [...curList, verifyEmailResponse.entity.id]);
                } else if (verifyEmailResponse.message[0]?.includes(otpExpiredErrorWord)) {
                    emailOtpVerifier.setDisabled(true);
                    emailOtpVerifier.cancelTimer();
                } else {
                    emailOtpVerifier.setDisabled(false);
                }
            }
        } else {
            await onSuccess({ otpIdList });
        }
        setLoading(false);
    };

    const onResendPhoneOtp = async () => {
        mobileOtpVerifier?.setOtpValue('');
        mobileOtpVerifier?.setErrorMessage('');
        mobileOtpVerifier?.setError(false);
        const response = await resendContextOtp({
            countryCode: countryCode,
            mobile: mobileNumber,
            context: mobileContext
        });
        if (response.status) {
            mobileOtpVerifier?.resetSeconds();
            mobileOtpVerifier?.setDisabled(false);
            mobileOtpVerifier?.inputRef.current?.focus();
        } else {
            mobileOtpVerifier?.setErrorMessage('Failed to resend the OTP. Try again!');
        }
    };

    const onResendEmailOtp = async () => {
        emailOtpVerifier.setOtpValue('');
        emailOtpVerifier.setErrorMessage('');
        emailOtpVerifier?.setError(false);
        const response = await resendContextOtp({
            email: emailId,
            mobile: mobileNumber,
            context: otpContextEnum.EMAIL_VERIFICATION
        });
        if (response.status) {
            emailOtpVerifier.resetSeconds();
            emailOtpVerifier.setDisabled(false);
            emailOtpVerifier.inputRef.current?.focus();
        } else {
            emailOtpVerifier.setErrorMessage('Failed to resend the OTP. Try again!');
        }
    };

    useEffect(() => {
        (async () => {
            if ((!shouldAskMobileOtp || mobileOtpVerifier?.isVerified) && emailOtpVerifier.isVerified) {
                setLoading(true);
                setIsOtpVerificationSuccess(true);
                await onSuccess({ otpIdList });
                setLoading(false);
            }
        })();
    }, [mobileOtpVerifier?.isVerified, emailOtpVerifier.isVerified, otpIdList, shouldAskMobileOtp]);

    return (
        <>
            <NextHeadTag {...HeadTagData?.['#VerifyOtpModal']} />

            <Modal
                show={show}
                onClose={loading ? null : onClose}
                width="max-w-[440px]"
                label={title}
                labelBorderClass="border-none"
                labelParaClass="h5-semibold text-primary-500"
                labelPadding="pb-4"
                labelSize=" "
                roundedClass="rounded-xl"
                marginClass="p-4 md:p-6"
                paddingClass=''
                height={`max-h-[90%] thin-scrollbar`}
                children={
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='space-y-4'>
                            {subtitle && <Text content={subtitle} className="p5-regular text-gray-900" />}
                            {/* for mobile verification or authentication */}
                            {mobileOtpVerifier && (<EnterOTPCard
                                key='VerifyMobile'
                                title={mobileOtpVerifier.otpContext === otpContextEnum.MOBILE_VERIFICATION ? texts?.VerifyMobile : texts?.VerifyItsYou}
                                description={replaceKeysWithValues(texts?.EnterOTPSentToMobile, { "{length}": otpLength })}
                                descriptionComponent={mobileOtpVerifier.otpContext === otpContextEnum.AUTHENTICATION ? <MobileAuthenticationMessage otpModes={otpModes} countryCode={countryCode} mobileNumber={mobileNumber} /> : null}
                                otpDestination={[appendCountryCodeAndFormatMobile(mobileNumber, countryCode)]}
                                onClickEdit={[editMobile]}
                                otpInputComponent={mobileOtpVerifier.OtpInputComponent}
                                resendOtpComponent={mobileOtpVerifier.ResendOtpComponent(onResendPhoneOtp)}
                                errorMessage={mobileOtpVerifier.errorMessage}
                                isVerified={mobileOtpVerifier.isVerified}
                            />)}
                            {/* for email verification> */}
                            <EnterOTPCard
                                key='VerifyEmail'
                                title={shouldAskMobileOtp ? texts?.VerifyEmail : ''}
                                description={replaceKeysWithValues(shouldAskMobileOtp || !shouldAuthenticateUser ? texts?.EnterOTPSentToEmail : texts?.EnterOTPToConfirm, { "{length}": otpLength })}
                                otpDestination={shouldAskMobileOtp || !shouldAuthenticateUser ?
                                    [emailId.toLowerCase()]
                                    : [
                                        `+${countryCode} ${mobileNumber.substr(countryCode.length)}`,
                                        emailId.toLowerCase()
                                    ]}
                                onClickEdit={shouldAskMobileOtp || !shouldAuthenticateUser ? [editEmail] : [editMobile, editEmail]}
                                otpInputComponent={emailOtpVerifier.OtpInputComponent}
                                resendOtpComponent={emailOtpVerifier.ResendOtpComponent(onResendEmailOtp)}
                                errorMessage={emailOtpVerifier.errorMessage}
                                isVerified={emailOtpVerifier.isVerified}
                                showInnerCard={shouldAskMobileOtp}
                            />
                            <Button
                                id="verifyOtpButton"
                                buttonType="primary"
                                widthClass="w-full"
                                isLoading={loading}
                                disabled={
                                    (shouldAskMobileOtp && mobileOtpVerifier?.otpValue?.length !== otpLength) || emailOtpVerifier.otpValue.length !== otpLength || (shouldAskMobileOtp && mobileOtpVerifier?.errorMessage) || emailOtpVerifier.errorMessage
                                }
                                buttonText={texts?.Verify}
                            />
                        </div>
                    </form>
                }
            />
        </>
    );
};
export default VerifyPhoneAndEmailModal;
