import Moment from 'moment';
import * as OpportunityHelper from "./opportunities/helper";

export function debounce(func, wait, immediate) {
    var timeout;
    return function executedFunction() {
        var context = this;
        var args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export const parseData = (data) => {
    return JSON.parse(data);
};

export const mailToGmail = (value) =>
    `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${value}`;

export const callToNumber = (value) => `tel:${value}`;

export const getNumberWithComma = (number) => {
    return number && number.toLocaleString('en-IN');
};

export const scrollToTargetAdjusted = (reference, headerOffset = 64) => {
    let elementPosition = reference.current.getBoundingClientRect().top;
    let offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
    });
};

export const getInitials = (fullName) => {
    let names = fullName.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

export const getNumberInLakhAndCrore = (value) => {
    const updatedValue = String(value).slice(0, 4);
    if (value >= 100000000) {
        const valueToShow =
            updatedValue[2] === '0'
                ? updatedValue.slice(0, 2)
                : updatedValue.slice(0, 2) + '.' + updatedValue.slice(2, 4);
        return `${valueToShow} Cr`;
    }
    if (value < 100000000 && value >= 10000000) {
        const valueToShow =
            updatedValue[1] === '0' && updatedValue[2] === '0'
                ? updatedValue.slice(0, 1)
                : updatedValue.slice(0, 1) + '.' + updatedValue.slice(1, 3);
        return `${valueToShow} Cr`;
    }
    if (value < 10000000 && value >= 1000000) {
        const valueToShow =
            updatedValue[2] === '0'
                ? updatedValue.slice(0, 2)
                : updatedValue.slice(0, 2) + '.' + updatedValue.slice(2, 4);
        return `${valueToShow} Lakh(s)`;
    }
    if (value < 1000000 && value >= 100000) {
        const valueToShow =
            updatedValue[1] === '0'
                ? updatedValue.slice(0, 1)
                : updatedValue.slice(0, 1) + '.' + updatedValue.slice(1, 3);
        return `${valueToShow} Lakh(s)`;
    }
    return getNumberWithComma(value);
};

export const amountToBeMultipleOf = (amount, investmentMultiple) =>
    amount % investmentMultiple === 0;

// DEPRICATED!
export const isAssetBackedLeasingOpportunity = (opportunityType) => OpportunityHelper.isAssetBackedLeasingOpportunity(opportunityType);

export const getDecimalNumberWithComma = (number, decimalPlace = 4) => {
    number = parseFloat(number).toFixed(decimalPlace);
    return number.replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
};

/**
 *  When we use  Moment for date format conversion, 
 *  we need to mention the current format of the date and in which format we want to convert.
 * 
 *  because on Chrome browser, date format conversion works fine without ,
 *  giving the current format of the date.
 *  
 *  But for Firefox and other browsers, 
 *  Moment will return an invalid date if the date is in string format.
 * 
 *  We can avoid invalid date errors by mentioning the date's current format.
 *  
 */
export const formatDate = (date, format = 'MMM D, YYYY') => {
    if(date && Moment(date).isValid()){
      return  Moment.utc(date).utcOffset('+05:30').format(format);
    } else if(date && Moment(date, 'MM-DD-YYYY', true).isValid()){
      return  Moment(date, 'MM-DD-YYYY').utc().utcOffset('+05:30').format(format);
    } else if(date && Moment(date, 'MM-DDD-YYYY', true).isValid()){
      return  Moment(date, 'MM-DDD-YYYY').utc().utcOffset('+05:30').format(format);
    }
    return '';
}

export const downloadUrl = async (url) => {
    var link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link.remove();
};

export const getNumberWithFourdecimal = (number) => {
    if (typeof number === 'string') {
        return Number(number).toFixed(4);
    }
    return number.toFixed(4);
};

export const stringifyData = (data) => {
    return JSON.stringify(data);
};

export const startTimer = (expiry, setMins, setSecs) => {
    let myInterval = setInterval(() => {
        let diff = Math.abs(expiry - new Date().getTime()) / 1000;
        setMins(String(Math.floor(diff / 60) % 60).padStart(2, '0'));
        setSecs(String(Math.floor(diff % 60)).padStart(2, '0'));
    }, 1000);
    return () => {
        clearInterval(myInterval);
    };
};

export const getMobileWithoutCountryCode = (mobile, countryCode) => {
    return mobile.replace(countryCode.substring(1), '');
};

export const getQueryParamsFromUrl = (search, query) => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get(query);
};

export const isUndefined = (value) => {
    return value === undefined;
};

export const getFormattedPhoneNumber = (number) => {
    const countryCode = number.slice(0, 2);
    const phoneNumber = number.slice(2);

    return `+${countryCode} ${phoneNumber}`;
};

export const getInvestorStatusStyles = (status) => {
    switch (status) {
        case 'ManualVerificationRequired':
            return 'bg-semantic-warning-light text-semantic-warning-base';
        case 'Verified':
            return 'bg-semantic-success-light text-semantic-success-base';
        case 'Rejected':
            return 'bg-semantic-error-light text-semantic-error-base';
        default:
            return 'bg-semantic-info-light text-semantic-info-base';
    }
};

export const getSheetData = (data, header) => {
    var fields = Object.keys(data[0]);
    var sheetData = data.map((row) => {
        return fields.map((fieldName) => {
            return row[fieldName] ? row[fieldName] : '';
        });
    });
    sheetData.unshift(header);
    return sheetData;
};

// DEPRICATED!
export const isCorporateDebtOpportunity = (opportunityType) => OpportunityHelper.isCorporateDebtOpportunity(opportunityType);
// DEPRICATED!
export const isVentureDebtOpportunity = (opportunityType) => OpportunityHelper.isVentureDebtOpportunity(opportunityType);
// DEPRICATED!
export const isTreasuryBillOpportunity = (opportunityType) => OpportunityHelper.isTreasuryBillOpportunity(opportunityType);
// DEPRICATED!
export const isInvoiceDiscountingOpportunity = (opportunityType) => OpportunityHelper.isInvoiceDiscountingOpportunity(opportunityType);

export const isValidJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

// output time in 00:00 format
export const formatTimeForTimer = (timeInSeconds) => {
   var minutes = Math.floor(timeInSeconds / 60);
    var remainingSeconds = timeInSeconds % 60;

    // Add leading zeros if necessary
    var formattedMinutes = String(minutes).padStart(2, '0');
    var formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return formattedMinutes + ':' + formattedSeconds;
}

/*
 * Replace words in a string
 * originalString
 * replacementPairs: [['font-bold', 'font-sembold'], ....]
 */
export const multiReplaceInString = (originalString, replacementPairs) => {
    replacementPairs.forEach(([replacingWord, replacedWord]) => {
        const regex = new RegExp(replacingWord, 'g');
        originalString = originalString?.replace(regex, replacedWord);
    });
    return originalString;
};

export const countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    return value.toString().split('.')[1].length || 0;
};


export const getNumberAsCurrency = (number, decimalCount) => {
    const formattedNumber = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: decimalCount,
        maximumFractionDigits: decimalCount,
    }).format(number);

    // Add space between the currency symbol and the number

    const returnVal = formattedNumber.replace('₹', '₹ ');
    if (returnVal === '₹ NaN') return `₹ -`;
    else return returnVal;
}