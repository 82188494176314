import { UrlParamsReplace } from './index';

export const GET_MAPPING = () => UrlParamsReplace('/survey/mapping');

export const ADD_FORM_ID_TO_RULE_ENGINE = () =>
    UrlParamsReplace('/survey/rules');

export const CREATE_SURVEY_FORM_RESPONSE = () =>
    UrlParamsReplace('/survey/response');

export const CREATE_USER_SURVEY_FORM_RESPONSE = () =>
    UrlParamsReplace('/usersurveylink/response');

export const GET_USER_SURVEY = (formId, surveyLinkId) =>
    UrlParamsReplace('/usersurveylink/:formId/:surveyLinkId', {
        formId,
        surveyLinkId,
    });
export const UPDATE_USER_SURVEY_FORM_VIEW_COUNT = () =>
    UrlParamsReplace('/usersurveylink/count');


