import { hasWindow } from '@services/http.service';
import {
    ADMIN_LOGIN_URL,
    LOGIN_USER_URL,
    SEND_CONTEXT_OTP_URL,
    VERIFY_CONTEXT_OTP_URL,
} from '@services/urls/session';
import * as CsrHttpService from "@http/csr-http.service";
import * as SsrHttpService from "@http/ssr-http.service";
import {
    createUserGclidRecord,
} from '@services/users.service';
import { getReferralCode } from '@services/identity.service';
import { setAuth } from '@services/identity.service';
import { getMapping } from '@services/survey.service';
import { isNotIfa, isValidResponseEntity } from '@helpers/utils';
import { saveItemToLocalStorage } from '@helpers/miscelleanous';

export const adminLogin = (username, password) => {
    if (hasWindow()) {
        return CsrHttpService.postWithOutAuth(ADMIN_LOGIN_URL(), { username, password });
    }
    return SsrHttpService.postWithOutAuth(ADMIN_LOGIN_URL(), { username, password });
};

// details: {mobile, context, email, countryCode, entityType="Ifa" for FinancialAdvisor else omit it}
export const sendContextOtp = (details) => {
    if (hasWindow()) {
        return CsrHttpService.postWithOutAuth(SEND_CONTEXT_OTP_URL(), details);
    }
    return SsrHttpService.postWithOutAuth(SEND_CONTEXT_OTP_URL(), details);
};

// details: {mobile, context, email, countryCode, entityType="Ifa" for FinancialAdvisor else omit it}
export const resendContextOtp = (details) => {
    return sendContextOtp({ ...details, resend: true });
};

// details: {mobile, context, otp}
export const verifyContextOtp = (details) => {
    if (hasWindow()) {
        return CsrHttpService.postWithOutAuth(VERIFY_CONTEXT_OTP_URL(), details);
    }
    return SsrHttpService.postWithOutAuth(VERIFY_CONTEXT_OTP_URL(), details);
};

export const loginUserV2 = (mobile, otpIdList, userInput, userId, accessToken) => {
    if (hasWindow()) {
        return CsrHttpService.postWithOutAuth(LOGIN_USER_URL(), { mobile, otpIdList, userId, accessToken, ...userInput });
    }
    return SsrHttpService.postWithOutAuth(LOGIN_USER_URL(), { mobile, otpIdList, userId, accessToken, ...userInput });
};

export const createSession = async ({
    mobile,
    otpIdList,
    userDetails,
    opportunityId,
    referralEntity,
    gclid,
    router,
    setMenus
}) => {
    const sessionResponse = await loginUserV2(mobile, otpIdList, userDetails);
    if (sessionResponse.status) {
        await setAuthData({ response: sessionResponse, opportunityId, referralEntity, router, setMenus, userExists: userDetails?.userExists });
        await setUserGclidRecord(sessionResponse, gclid);
    }
    return sessionResponse;
}

const setUserGclidRecord = async (response, gclid) => {
    if (gclid) {
        const details = {
            userId: response.entity?.userid,
            gclid,
            state: 'Registered',
            isActive: true,
        };
        await createUserGclidRecord(details);
    }
};

const setAuthData = async ({ response, opportunityId, referralEntity, setMenus, router, userExists }) => {
    const user = response?.entity;
    setAuth(user);
    await setMenus();
    const mapData = await getMapping();
    if (isValidResponseEntity(mapData)) {
        saveItemToLocalStorage('survey', mapData?.entity);
    }
    if (router?.asPath === '/refer-and-earn' && isNotIfa(user)) {
        window.location.reload('/refer-and-earn');
        return;
    }
    navigateTo({ user, opportunityId, referralEntity, router, userExists });
};

/** 
 * Post signup we require to fire conversion pixels,
 * and for this we have to identify if it was a signup or login
 * for this we are using isreturnvisit and referrer code query string in GTM
 * If we change the landing page, use the same query params for that page as well
*/
const navigateTo = async ({ opportunityId, referralEntity, router }) => {
    const referralCode = getReferralCode();
    const isReferAndEarnPage = router?.pathname === '/referandearn';
    const basePath = '/questionnaire';

    let queryParams = '';
    if (referralCode) {
        queryParams =
            referralEntity === 'usoc'
                ? addParam(queryParams, `usoc=${referralCode}`)
                : addParam(queryParams, `referrer=${referralCode}`);
    }

    queryParams = addParam(queryParams, 'isreturnvisit=false')

    if (isReferAndEarnPage) {
        queryParams = addParam(queryParams, 'referandearn=true');
    } else if (opportunityId) {
        queryParams = addParam(queryParams, `opportunityId=${opportunityId}`);
    }

    router.push(`${basePath}${queryParams}`);
};

const addParam = (queryParams, param) => {
    return queryParams
    ? queryParams + `&${param}`
    : `?${param}`;
}
